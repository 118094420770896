<template>
  <mdb-navbar color="elegant" dark class="navnav">
    <mdb-navbar-brand
      v-if="!(worker === 'manufacturing_man' || worker === '9055')"
    >
      {{ worker }}
    </mdb-navbar-brand>
    <mdb-navbar-toggler>
      <mdb-navbar-nav left v-if="worker === 'manufacturing_man'">
        <mdb-dropdown tag="li" class="nav-item">
          <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed
            >Таблицы</mdb-dropdown-toggle
          >
          <mdb-dropdown-menu>
            <mdb-dropdown-item @click="openDeliveryTable"
              >Лист отправки</mdb-dropdown-item
            >
            <mdb-dropdown-item @click="openToolsTable">План</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
      <mdb-navbar-nav left v-if="worker === '9055'">
        <mdb-dropdown
          v-if="worker === '9055'"
          tag="li"
          class="nav-item animated fadeInDown"
        >
          <!-- <mdb-icon icon="caret-up" /> -->
          <mdb-dropdown-toggle
            icon="caret-up"
            tag="a"
            navLink
            slot="toggle"
            waves-fixed
          ></mdb-dropdown-toggle>
          <mdb-dropdown-menu>
            <mdb-dropdown-item @click="openDesignerLoad" active
              >Нагрузка дизайнеров</mdb-dropdown-item
            >
            <mdb-dropdown-item @click="openReport">Отчет</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
        <mdb-navbar-nav v-if="isAopenReport" class="animated fadeInDown">
          <mdb-nav-item
            @click="sortYesterday"
            :class="{ active: isActiveYesterday }"
          >
            Вчера
          </mdb-nav-item>
          <mdb-nav-item @click="sortToday" :class="{ active: isActiveToday }">
            Сегодня
          </mdb-nav-item>
          <mdb-nav-item
            @click="sortTomorrow"
            :class="{ active: isActiveTomorrow }"
          >
            Завтра
          </mdb-nav-item>
          <mdb-nav-item
            @click="sortAfterTomorrow"
            :class="{ active: isActiveAfterTomorrow }"
          >
            Послезавтра
          </mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar-nav>

      <mdb-navbar-nav right>
        <mdb-nav-item
          v-if="!(worker === 'manufacturing_man') && !(worker === '9055')"
          @click="card_all_hidden"
        >
          <mdb-icon icon="arrows-alt-v" size="lg" class="btn_icon" />
        </mdb-nav-item>
        <mdb-nav-item @click.prevent="logout">Выход</mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>
<style >
.navnav {
  z-index: 9999;
}
</style>
<script>
import { bus } from "@/main";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbIcon,
  mdbDropdown,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbDropdownItem,
  mdbTooltip,
} from "mdbvue";
export default {
  name: "NavbarPage",
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbIcon,
    mdbDropdown,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbDropdownItem,
    mdbTooltip,
  },
  data() {
    return {
      worker: "",
      iconRefresh: true,
      cardALLHidden: true,
      CardsRefresh: 1,

      isActiveYesterday: false,
      isActiveToday: true,
      isActiveAfterTomorrow: false,
      isActiveTomorrow: false,

      isAopenReport: true,
    };
  },
  methods: {
    sortYesterday() {
      bus.$emit("yesterday");
      this.isActiveToday = false;
      this.isActiveAfterTomorrow = false;
      this.isActiveTomorrow = false;
      this.isActiveYesterday = true;
    },
    sortToday() {
      bus.$emit("today");
      this.isActiveToday = true;
      this.isActiveAfterTomorrow = false;
      this.isActiveTomorrow = false;
      this.isActiveYesterday = false;
    },
    sortTomorrow() {
      bus.$emit("tomorrow");
      this.isActiveToday = false;
      this.isActiveAfterTomorrow = false;
      this.isActiveTomorrow = true;
      this.isActiveYesterday = false;
    },
    sortAfterTomorrow() {
      bus.$emit("afterTomorrow");
      this.isActiveToday = false;
      this.isActiveAfterTomorrow = true;
      this.isActiveTomorrow = false;
      this.isActiveYesterday = false;
    },
    reloadManualbtn() {
      bus.$emit("refreshing", this.CardsRefresh + 1);
    },
    icon_refresh() {
      bus.$emit("icon", this.iconRefresh);
      this.iconRefresh = !this.iconRefresh;
    },
    card_all_hidden() {
      bus.$emit("cards_hidden", this.cardALLHidden);
      this.cardALLHidden = !this.cardALLHidden;
    },
    openDeliveryTable() {
      bus.$emit("deliveryTableB", true);
    },
    openToolsTable() {
      bus.$emit("toolsTableB", true);
    },
    openDesignerLoad() {
      bus.$emit("openDesignerLoadB");
      this.isAopenReport = true;
    },
    openReport() {
      bus.$emit("openReportB");
      this.isAopenReport = false;
      this.isActiveToday = true;
      this.isActiveAfterTomorrow = false;
      this.isActiveTomorrow = false;
      this.isActiveYesterday = false;
    },
    async logout() {
      await this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push("/");
    },
  },
  async mounted() {
    this.worker = await localStorage.getItem("user-login");
  },
};
</script>
